import { BrigadeCoverageApplianceCategoryGroup } from "../../.rest-hooks/types/coverage.yml";
import { applianceCategoryGroupLabels } from "./applianceCategory";

export const brigadeCoverageGroups = Object.values(
  BrigadeCoverageApplianceCategoryGroup,
);

export const formatBrigadeCoverageCategoryGroupLabel = (
  category: BrigadeCoverageApplianceCategoryGroup | undefined,
) => {
  if (!category) {
    return "-";
  }
  const label = applianceCategoryGroupLabels[category];
  return label.substring(0, label.indexOf("(") - 1);
};
