import { Badge, Warning } from "@app/design-system";
import { formatNumber } from "../../../utils/formatNumber/formatNumber";

interface UncontainedIncidentCountProps {
  count: number;
}

const UncontainedIncidentCount = ({ count }: UncontainedIncidentCountProps) => {
  return (
    <Badge icon={Warning} variant="warning">
      {formatNumber(count)} uncontained incidents
    </Badge>
  );
};

export default UncontainedIncidentCount;
