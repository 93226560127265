import { StatusMessage } from "@app/design-system";
import SeverityIcon from "@app/design-system/src/components/SeverityIcon/SeverityIcon";
import styled from "styled-components";
import type { BrigadeCoverageSnapshot } from "../../../../.rest-hooks/types";
import getBrigadeCoverageSnapshotType from "../../../config/getBrigadeCoverageSnapshotType";
import { formatCoverageNet } from "./formatCoverageNet";

const StyledCoverageNetIndicator = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    color: ${(p) => p.theme.colors.warning.icon};
  }
`;

interface CoverageNetIndicatorProps {
  snapshot: BrigadeCoverageSnapshot | undefined;
}

const CoverageNetIndicator = ({ snapshot }: CoverageNetIndicatorProps) => {
  const filteredSnapshot = getBrigadeCoverageSnapshotType(snapshot);

  if (
    filteredSnapshot === "error" ||
    filteredSnapshot.coverageImpactSeverity === "unknown"
  ) {
    return (
      <StatusMessage
        tooltip="Coverage gap calculation has failed. Contact ICT or check back later."
        variant="error"
      >
        Failed
      </StatusMessage>
    );
  }

  return (
    <StyledCoverageNetIndicator>
      {filteredSnapshot.coverageImpactSeverity !== "no-impact" && (
        <SeverityIcon
          disabled={!!filteredSnapshot.assessment}
          size="sm"
          severity={filteredSnapshot.coverageImpactSeverity}
        />
      )}
      {formatCoverageNet(filteredSnapshot.coverageNet)}
    </StyledCoverageNetIndicator>
  );
};

export default CoverageNetIndicator;
