import { StatusIndicator, type StatusIndicatorSize } from "@app/design-system";
import type { FireDangerRating } from "../../../../.rest-hooks/types/common.yml";
import {
  fireDangerRatingIcons,
  formatFireDangerRating,
} from "../../../config/fireDangerRating";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";

interface FireDangerRatingIndicatorProps {
  fireDangerRating: FireDangerRating | undefined;
  size?: StatusIndicatorSize;
  "data-testid"?: string;
}

const FireDangerRatingIndicator = ({
  fireDangerRating,
  size,
  "data-testid": dataTestId,
}: FireDangerRatingIndicatorProps) => {
  if (!fireDangerRating) {
    return <FallbackElement />;
  }
  const icon = fireDangerRatingIcons[fireDangerRating];
  const label = formatFireDangerRating(fireDangerRating);

  return (
    <StatusIndicator icon={icon} size={size} data-testid={dataTestId}>
      {label}
    </StatusIndicator>
  );
};

export default FireDangerRatingIndicator;
