/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetCoverageAircraft200,
  GetCoverageAircraftParams,
  GetCoverageDistricts200,
  GetCoverageDistrictsIdBrigades200,
  GetCoverageDistrictsIdBrigadesParams,
  GetCoverageDistrictsParams,
  PutCoverageBrigadesIdAssess200,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";
import type { AssessBrigadeCoverageDeficitInput } from "./types/coverage.yml";

/**
 * Gets a list of aircraft coverage snapshots for each Fire Danger Rating currently assigned to one or more fire weather districts.

 * @summary Get aircraft coverage snapshots
 */
export const getCoverageAircraft = (
  params?: GetCoverageAircraftParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetCoverageAircraft200>> => {
  return axios.get(`/coverage/aircraft`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCoverageAircraftQueryKey = (
  params?: GetCoverageAircraftParams,
) => {
  return [`/coverage/aircraft`, ...(params ? [params] : [])] as const;
};

export const getGetCoverageAircraftQueryOptions = <
  TData = Awaited<ReturnType<typeof getCoverageAircraft>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetCoverageAircraftParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageAircraft>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCoverageAircraftQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCoverageAircraft>>
  > = ({ signal }) => getCoverageAircraft(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCoverageAircraft>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCoverageAircraftQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCoverageAircraft>>
>;
export type GetCoverageAircraftQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get aircraft coverage snapshots
 */
export const useGetCoverageAircraft = <
  TData = Awaited<ReturnType<typeof getCoverageAircraft>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetCoverageAircraftParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageAircraft>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCoverageAircraftQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets a list of district coverage snapshots
 * @summary Get district coverage snapshots
 */
export const getCoverageDistricts = (
  params?: GetCoverageDistrictsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetCoverageDistricts200>> => {
  return axios.get(`/coverage/districts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCoverageDistrictsQueryKey = (
  params?: GetCoverageDistrictsParams,
) => {
  return [`/coverage/districts`, ...(params ? [params] : [])] as const;
};

export const getGetCoverageDistrictsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCoverageDistricts>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetCoverageDistrictsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageDistricts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCoverageDistrictsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCoverageDistricts>>
  > = ({ signal }) => getCoverageDistricts(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCoverageDistricts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCoverageDistrictsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCoverageDistricts>>
>;
export type GetCoverageDistrictsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get district coverage snapshots
 */
export const useGetCoverageDistricts = <
  TData = Awaited<ReturnType<typeof getCoverageDistricts>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetCoverageDistrictsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageDistricts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCoverageDistrictsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets a list of coverage snapshots for brigades within a specific district.

 * @summary Get district's brigade coverage snapshots
 */
export const getCoverageDistrictsIdBrigades = (
  id: string,
  params?: GetCoverageDistrictsIdBrigadesParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetCoverageDistrictsIdBrigades200>> => {
  return axios.get(`/coverage/districts/${id}/brigades`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCoverageDistrictsIdBrigadesQueryKey = (
  id: string,
  params?: GetCoverageDistrictsIdBrigadesParams,
) => {
  return [
    `/coverage/districts/${id}/brigades`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCoverageDistrictsIdBrigadesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetCoverageDistrictsIdBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCoverageDistrictsIdBrigadesQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>
  > = ({ signal }) =>
    getCoverageDistrictsIdBrigades(id, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCoverageDistrictsIdBrigadesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>
>;
export type GetCoverageDistrictsIdBrigadesQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get district's brigade coverage snapshots
 */
export const useGetCoverageDistrictsIdBrigades = <
  TData = Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetCoverageDistrictsIdBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCoverageDistrictsIdBrigades>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCoverageDistrictsIdBrigadesQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Allows users to assess the coverage deficit for a specific brigade by providing an assessment, reason, and optional additional notes. The assessment can be based on whether the brigade is covered by specific resources or acknowledged as not covered. Use this endpoint to update the coverage deficit assessment for the brigade identified by the `{id}` parameter. Assessments are valid for 12 hours.

 * @summary Assess brigade coverage deficit
 */
export const putCoverageBrigadesIdAssess = (
  id: string,
  assessBrigadeCoverageDeficitInput: AssessBrigadeCoverageDeficitInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutCoverageBrigadesIdAssess200>> => {
  return axios.put(
    `/coverage/brigades/${id}/assess`,
    assessBrigadeCoverageDeficitInput,
    options,
  );
};

export const getPutCoverageBrigadesIdAssessMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCoverageBrigadesIdAssess>>,
    TError,
    { id: string; data: AssessBrigadeCoverageDeficitInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCoverageBrigadesIdAssess>>,
  TError,
  { id: string; data: AssessBrigadeCoverageDeficitInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCoverageBrigadesIdAssess>>,
    { id: string; data: AssessBrigadeCoverageDeficitInput }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCoverageBrigadesIdAssess(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCoverageBrigadesIdAssessMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCoverageBrigadesIdAssess>>
>;
export type PutCoverageBrigadesIdAssessMutationBody =
  AssessBrigadeCoverageDeficitInput;
export type PutCoverageBrigadesIdAssessMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Assess brigade coverage deficit
 */
export const usePutCoverageBrigadesIdAssess = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCoverageBrigadesIdAssess>>,
    TError,
    { id: string; data: AssessBrigadeCoverageDeficitInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPutCoverageBrigadesIdAssessMutationOptions(options);

  return useMutation(mutationOptions);
};
