import { Text, type TypographySize } from "@app/design-system";

interface BannerCountProps {
  count: number | undefined;
  label: string;
  size?: TypographySize;
}

const BannerCount = ({
  count,
  label,
  size = "bodyDefault",
}: BannerCountProps) => {
  return (
    <Text size={size}>
      {(
        <>
          {count?.toLocaleString("en-US")} {label}
        </>
      ) || <>&mdash; {label}</>}
    </Text>
  );
};

export default BannerCount;
