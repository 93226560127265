import { Checkbox, EndTD, Tooltip } from "@app/design-system";
import { createColumnHelper } from "@tanstack/react-table";
import type { FireDangerRating } from "../../../../.rest-hooks/types";
import type { BrigadeCoverageSnapshotValid } from "../../../../.rest-hooks/types/coverage.yml";
import type { BrigadeCoverageCategoryGroupSnapshot } from "../../../../.rest-hooks/types/coverage.yml/brigadeCoverageCategoryGroupSnapshot";
import {
  type ApplianceCategoryGroup,
  applianceCategoryGroupLabels,
} from "../../../config/applianceCategory";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { StyledTruncate } from "../../fire-modelling/PredictionsListDataTable/columns";
import FireDangerRatingIndicator from "../../ui/FireDangerRatingIndicator/FireDangerRatingIndicator";
import AssessCoverageGapButton from "../AssessCoverageGapButton/AssessCoverageGapButton";
import BrigadeCoverageAssessmentBadge from "../BrigadeCoverageAssessmentBadge/BrigadeCoverageAssessmentBadge";
import CoverageNetIndicatorCell from "../CoverageNetIndicator/CoverageNetIndicatorCell";

const getCategoryGroupHeader = (category: ApplianceCategoryGroup) => {
  const label = applianceCategoryGroupLabels[category];
  const message = label.substring(label.indexOf("(") + 1, label.indexOf(")"));
  const header = label.substring(0, label.indexOf("(") - 1);

  return (
    <Tooltip message={message}>
      <div>{header}</div>
    </Tooltip>
  );
};

interface GetCategoryGroupCountProps {
  category: BrigadeCoverageCategoryGroupSnapshot | undefined;
}

export const getCategoryGroupCount = ({
  category,
}: GetCategoryGroupCountProps) => {
  if (!category) {
    return <FallbackElement />;
  }
  return `${category.satisfiedCount}/${category.totalCount}`;
};

export type BrigadeCoverageSnapshotRow = {
  assessment: BrigadeCoverageSnapshotValid["assessment"];
  brigadeName: string;
  "cat-1-4": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-6": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-7-8": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-9": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-10-11": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-13": BrigadeCoverageCategoryGroupSnapshot | undefined;
  "cat-15": BrigadeCoverageCategoryGroupSnapshot | undefined;
  coverageImpactSeverity:
    | BrigadeCoverageSnapshotValid["coverageImpactSeverity"]
    | undefined;
  coverageNet: number | undefined;
  districtId: string;
  fireDangerRating: FireDangerRating | undefined;
  id: string;
  snapshot: BrigadeCoverageSnapshotValid | undefined;
};

const columnHelper = createColumnHelper<BrigadeCoverageSnapshotRow>();

export const columns = [
  columnHelper.display({
    header: ({ table }) => {
      return (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      );
    },
    id: "assess",
    size: 60,
    cell: ({ row }) => {
      const { id, coverageImpactSeverity } = row.original;

      return (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!coverageImpactSeverity}
          value={id}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
  }),
  columnHelper.accessor("coverageNet", {
    enableSorting: false,
    header: "Coverage",
    size: 80,
    cell: (props) => {
      return (
        <CoverageNetIndicatorCell snapshot={props.row.original.snapshot} />
      );
    },
  }),
  columnHelper.accessor("brigadeName", {
    enableSorting: true,
    header: "Brigade name",
    size: 240,
    cell: (props) => {
      const brigadeName = props.cell.getValue();

      return <StyledTruncate>{brigadeName}</StyledTruncate>;
    },
  }),
  columnHelper.accessor("fireDangerRating", {
    enableSorting: false,
    header: "Fire danger rating",
    size: 170,
    cell: (props) => {
      const fireDangerRating = props.cell.getValue();

      if (!fireDangerRating) return <FallbackElement />;

      return <FireDangerRatingIndicator fireDangerRating={fireDangerRating} />;
    },
  }),
  columnHelper.accessor("assessment", {
    enableSorting: false,
    header: "Status",
    size: 144,
    cell: (props) => {
      const assessment = props.cell.getValue();
      return <BrigadeCoverageAssessmentBadge assessment={assessment} />;
    },
  }),
  columnHelper.accessor("cat-1-4", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-1-4");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-6", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-6");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-7-8", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-7-8");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-9", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-9");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-10-11", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-10-11");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-13", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-13");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.accessor("cat-15", {
    enableSorting: false,
    header: () => {
      return getCategoryGroupHeader("cat-15");
    },
    size: 96,
    maxSize: 96,
    cell: (props) => {
      const category = props.cell.getValue();

      return getCategoryGroupCount({ category });
    },
  }),
  columnHelper.display({
    header: "",
    id: "action",
    size: 120,
    cell: (props) => {
      const { snapshot } = props.row.original;
      const { districtId } = props.row.original;

      return (
        <EndTD>
          <AssessCoverageGapButton
            districtId={districtId}
            labelVariant="short"
            snapshot={snapshot}
            size="sm"
            variant="secondary"
          />
        </EndTD>
      );
    },
  }),
];
