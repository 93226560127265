/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Severity is a set of levels used across the system to express the relative importance or seriousness of different states or conditions.

 */
export type SeverityLevel = (typeof SeverityLevel)[keyof typeof SeverityLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeverityLevel = {
  severe: "severe",
  high: "high",
  moderate: "moderate",
  low: "low",
  "very-low": "very-low",
  "no-impact": "no-impact",
  unknown: "unknown",
} as const;
