import { Text } from "@app/design-system";
import styled from "styled-components";
import type { IncidentAlertLevel } from "../../../../.rest-hooks/types/incidents.yml";
import AlertLevelIcon from "../AlertLevelIcon/AlertLevelIcon";

const StyledBannerIconCount = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

interface BannerIconCountProps {
  count: number;
  alertLevel: IncidentAlertLevel;
}

const BannerIconCount = ({ count, alertLevel }: BannerIconCountProps) => {
  return (
    <StyledBannerIconCount>
      <AlertLevelIcon
        size="md"
        alertLevel={alertLevel}
        tooltipPlacement="bottom"
      />
      <Text size={count ? "subtitleSm" : "bodyDefault"}>
        {count || <>&mdash;</>}
      </Text>
    </StyledBannerIconCount>
  );
};

export default BannerIconCount;
