import { FieldGrid, ModalHighlightSlot, Text } from "@app/design-system";
import styled from "styled-components";
import type { BrigadeCoverageSnapshotValid } from "../../../../.rest-hooks/types/coverage.yml";
import {
  brigadeCoverageGroups,
  formatBrigadeCoverageCategoryGroupLabel,
} from "../../../config/brigadeCoverage";
import makeTestId from "../../../utils/makeTestId";
import FireDangerRatingIndicator from "../../ui/FireDangerRatingIndicator/FireDangerRatingIndicator";
import { getCategoryGroupCount } from "../BrigadeCoverageSnapshotList/columns";
import CoverageNetIndicator from "../CoverageNetIndicator/CoverageNetIndicator";

const StyledCoverageGapSnapshotHighlight = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledBody = styled.div`
  display: grid;
  gap: 0.25rem;
`;

interface CoverageGapSnapshotHighlightProps {
  snapshot: BrigadeCoverageSnapshotValid;
  "data-testid"?: string;
}

const CoverageGapSnapshotHighlight = ({
  snapshot,
  "data-testid": dataTestId,
}: CoverageGapSnapshotHighlightProps) => {
  return (
    <ModalHighlightSlot data-testid={dataTestId}>
      <StyledCoverageGapSnapshotHighlight>
        {snapshot.fireDangerRating && (
          <FireDangerRatingIndicator
            data-testid={makeTestId(dataTestId, "fdr")}
            fireDangerRating={snapshot.fireDangerRating}
            size="eyebrow"
          />
        )}
        <StyledBody>
          <Text size="subtitleLg" data-testid={makeTestId(dataTestId, "name")}>
            {snapshot.brigadeName}
          </Text>
          <FieldGrid>
            <FieldGrid.Item label="Coverage:">
              <CoverageNetIndicator snapshot={snapshot} />
            </FieldGrid.Item>
            {brigadeCoverageGroups.map((category) => {
              const label = formatBrigadeCoverageCategoryGroupLabel(category);
              return (
                <FieldGrid.Item label={`${label}:`} key={category}>
                  {getCategoryGroupCount({
                    category: snapshot.categoryGroups[category],
                  })}
                </FieldGrid.Item>
              );
            })}
          </FieldGrid>
        </StyledBody>
      </StyledCoverageGapSnapshotHighlight>
    </ModalHighlightSlot>
  );
};

export default CoverageGapSnapshotHighlight;
