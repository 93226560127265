import { useIsMinWidth } from "@app/design-system";
import styled from "styled-components";
import type { GetAggregatesIncidents200Meta } from "../../../../.rest-hooks/types";
import type { IncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import SkeletonIncidentNavBanner from "../IncidentNavHeader/SkeletonIncidentNavBanner";
import BannerCount from "./BannerCount";
import BannerIconCount from "./BannerIconCount";
import UncontainedIncidentCount from "./UncontainedIncidentCount";

const StyledBullet = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
  line-height: 2rem;
`;

export const Bullet = () => {
  return <StyledBullet>&bull;</StyledBullet>;
};

const StyledIncidentsNavBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const StyledCounts = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledUncontainedIncidentCount = styled.div`
  margin: -0.25rem 0;
`;

const StyledAlertLevels = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface IncidentsNavBannerProps {
  aggregateData: GetAggregatesIncidents200Meta | undefined;
  isLoading: boolean;
  scope?: IncidentsScope;
}

const IncidentsNavBanner = (props: IncidentsNavBannerProps) => {
  const { aggregateData, isLoading, scope } = props;

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");
  if (isLoading || !aggregateData) {
    return <SkeletonIncidentNavBanner />;
  }

  const { total, alertLevel, burntArea, numFirefighter, numVehicles } =
    aggregateData;

  return (
    <StyledIncidentsNavBanner>
      {isTabletLandscapeAndAbove && (
        <StyledCounts>
          {scope?.status === "uncontained" ? (
            <StyledUncontainedIncidentCount>
              <UncontainedIncidentCount count={total} />
            </StyledUncontainedIncidentCount>
          ) : (
            <BannerCount count={total} label="Incidents" size="subtitleSm" />
          )}
          <Bullet />
          <BannerCount count={burntArea} label="ha" />
          <Bullet />
          <BannerCount count={numVehicles} label="Vehicles" />
          <Bullet />
          <BannerCount count={numFirefighter} label="Firefighters" />
        </StyledCounts>
      )}
      <StyledAlertLevels>
        <BannerIconCount
          alertLevel="Emergency Warning"
          count={alertLevel["Emergency Warning"]}
        />
        <BannerIconCount
          alertLevel="Watch and Act"
          count={alertLevel["Watch and Act"]}
        />
        <BannerIconCount alertLevel="Advice" count={alertLevel.Advice} />
      </StyledAlertLevels>
    </StyledIncidentsNavBanner>
  );
};

export default IncidentsNavBanner;
