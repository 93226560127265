import {
  Button,
  type ButtonSize,
  type ButtonVariant,
} from "@app/design-system";
import { useBoolean } from "usehooks-ts";
import type { BrigadeCoverageSnapshotValid } from "../../../../.rest-hooks/types/coverage.yml";
import CoverageGapAssessmentModal from "./CoverageGapAssessmentModal";

interface AssessCoverageGapButtonProps {
  districtId: string;
  labelVariant?: "default" | "short";
  snapshot: BrigadeCoverageSnapshotValid | undefined;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const AssessCoverageGapButton = ({
  districtId,
  labelVariant = "default",
  snapshot,
  size,
  variant = "secondary",
}: AssessCoverageGapButtonProps) => {
  const { setFalse: close, setTrue: open, value: isOpen } = useBoolean();

  return (
    <>
      <Button disabled={!snapshot} onClick={open} size={size} variant={variant}>
        {labelVariant === "short" ? "Assess" : "Assess coverage gap"}
      </Button>
      {snapshot && (
        <CoverageGapAssessmentModal
          districtId={districtId}
          isOpen={isOpen}
          onClose={close}
          snapshot={snapshot}
        />
      )}
    </>
  );
};

export default AssessCoverageGapButton;
