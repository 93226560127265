import { Badge, InfoFilled, type BadgeVariant } from "@app/design-system";
import type {
  BrigadeCoverageDeficitAssessment,
  BrigadeCoverageDeficitAssessmentType,
} from "../../../../.rest-hooks/types/coverage.yml";
import {
  brigadeCoverageAssessmentReasonLabels,
  brigadeCoverageAssessmentTypeLabels,
} from "../../../config/brigadeCoverageAssessment";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";

const assessmentBadgeVariants: Record<
  BrigadeCoverageDeficitAssessmentType,
  BadgeVariant
> = {
  acknowledged: "neutral",
  covered: "endorsed",
};

interface BrigadeCoverageAssessmentBadgeProps {
  assessment: BrigadeCoverageDeficitAssessment | undefined;
}

const BrigadeCoverageAssessmentBadge = ({
  assessment,
}: BrigadeCoverageAssessmentBadgeProps) => {
  const { options } = useDeveloperOptions();

  if (!assessment) {
    return "—";
  }

  let parsedAssessment: BrigadeCoverageDeficitAssessment;

  if (typeof assessment === "string") {
    parsedAssessment = JSON.parse(assessment);
  } else {
    parsedAssessment = assessment;
  }

  const variant = assessmentBadgeVariants[parsedAssessment.assessment];

  return (
    <Badge
      showTooltipIcon={options.badgeStyle === "classic"}
      {...(options.badgeStyle === "modern" && { icon: InfoFilled })}
      tooltip={
        <>
          <div>
            {brigadeCoverageAssessmentReasonLabels[parsedAssessment.reason]}
          </div>
          <div>{parsedAssessment.note}</div>
          <div>
            Completed at:{" "}
            {getFormattedDateAndTime(parsedAssessment.createdAt * 1000)}
          </div>
        </>
      }
      variant={variant}
    >
      {brigadeCoverageAssessmentTypeLabels[parsedAssessment.assessment]}
    </Badge>
  );
};

export default BrigadeCoverageAssessmentBadge;
