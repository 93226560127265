import {
  Checkbox,
  Field,
  RadioButtonGroup,
  TextArea,
} from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type {
  AcknowledgedBrigadeCoverageDeficitAssessmentReason,
  BrigadeCoverageDeficitAssessment,
  CoveredBrigadeCoverageDeficitAssessmentReason,
} from "../../../../.rest-hooks/types/coverage.yml";
import {
  acknowledgedAssessmentReasonOptions,
  coveredAssessmentReasonOptions,
} from "../../../config/brigadeCoverageAssessment";

const StyledCoverageGapAssessmentForm = styled.div`
  display: grid;
  gap: 16px;
`;

export interface CoverageGapAssessmentFormValues {
  assessmentNote: string;
  assessmentReason:
    | AcknowledgedBrigadeCoverageDeficitAssessmentReason
    | CoveredBrigadeCoverageDeficitAssessmentReason
    | "";
  confirmDismissal: boolean;
}

export const getDefaultCoverageGapAssessmentFormValues = (
  assessment: BrigadeCoverageDeficitAssessment | undefined,
): CoverageGapAssessmentFormValues => {
  return {
    assessmentNote: assessment?.note ?? "",
    assessmentReason: assessment?.reason ?? "",
    confirmDismissal: false,
  };
};

const CoverageGapAssessmentForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<CoverageGapAssessmentFormValues>();

  return (
    <StyledCoverageGapAssessmentForm>
      <Controller
        control={control}
        name="assessmentReason"
        render={({ field: fieldProps, fieldState }) => {
          return (
            <RadioButtonGroup
              error={fieldState.error}
              label="Mark coverage gap as:"
              name={fieldProps.name}
              onChange={fieldProps.onChange}
              onBlur={fieldProps.onBlur}
              value={fieldProps.value}
            >
              {coveredAssessmentReasonOptions.map(({ label, value }) => (
                <RadioButtonGroup.Item
                  key={value}
                  label={label}
                  value={value}
                />
              ))}
              {acknowledgedAssessmentReasonOptions.map(({ label, value }) => (
                <RadioButtonGroup.Item
                  key={value}
                  label={label}
                  value={value}
                />
              ))}
            </RadioButtonGroup>
          );
        }}
        rules={{
          validate: (value) => !!value || "Please select an assessment reason",
        }}
      />
      <Field
        error={errors.assessmentNote}
        htmlFor="assessmentNote"
        label="Additional notes, resources and details"
      >
        <TextArea
          id="assessmentNote"
          maxLength={300}
          placeholder="Add information about your note here"
          validationStatus={errors.assessmentNote && "error"}
          {...register("assessmentNote")}
        />
      </Field>
      <Controller
        control={control}
        name="confirmDismissal"
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              error={error}
              label="I confirm that this action will dismiss the current coverage gaps for the next 12 hours"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
        rules={{
          validate: (value) => !!value || "Please acknowledge dismissal",
        }}
      />
    </StyledCoverageGapAssessmentForm>
  );
};

export default CoverageGapAssessmentForm;
