import type { FormControlOption } from "@app/design-system";
import {
  AcknowledgedBrigadeCoverageDeficitAssessmentReason,
  type BrigadeCoverageDeficitAssessmentType,
  CoveredBrigadeCoverageDeficitAssessmentReason,
} from "../../.rest-hooks/types/coverage.yml";
import type { CoverageGapAssessmentFormValues } from "../components/coverage/AssessCoverageGapButton/CoverageGapAssessmentForm";

const acknowledgedAssessmentReasons = Object.values(
  AcknowledgedBrigadeCoverageDeficitAssessmentReason,
);

const coveredAssessmentReasons = Object.values(
  CoveredBrigadeCoverageDeficitAssessmentReason,
);

export const brigadeCoverageAssessmentTypeLabels: Record<
  BrigadeCoverageDeficitAssessmentType,
  string
> = {
  acknowledged: "Acknowledged",
  covered: "Covered",
};

export const brigadeCoverageAssessmentReasonLabels: Record<
  | CoveredBrigadeCoverageDeficitAssessmentReason
  | AcknowledgedBrigadeCoverageDeficitAssessmentReason,
  string
> = {
  "covered-by-aircraft-capability": "Covered by aircraft capability",
  "covered-by-neighbouring-resources": "Covered by neighbouring resources",
  "covered-by-partner-agency": "Covered by partner agency",
  "covered-by-specialist-resources": "Covered by specialist resources",
  "appliances-being-serviced": "Appliances are being serviced",
  "sent-out-of-area-strike-team": "Sent an out of area strike team",
  "unable-to-cover": "Unable to cover gap at this time",
};

export const acknowledgedAssessmentReasonOptions: FormControlOption<AcknowledgedBrigadeCoverageDeficitAssessmentReason>[] =
  acknowledgedAssessmentReasons.map((reason) => ({
    label: brigadeCoverageAssessmentReasonLabels[reason],
    value: reason,
  }));

export const coveredAssessmentReasonOptions: FormControlOption<CoveredBrigadeCoverageDeficitAssessmentReason>[] =
  coveredAssessmentReasons.map((reason) => ({
    label: brigadeCoverageAssessmentReasonLabels[reason],
    value: reason,
  }));

export const getCoverageGapAssessment = (
  assessment: CoverageGapAssessmentFormValues,
) => {
  if (
    assessment.assessmentReason in
    AcknowledgedBrigadeCoverageDeficitAssessmentReason
  ) {
    return {
      assessment: "acknowledged",
      note: assessment.assessmentNote,
      reason:
        assessment.assessmentReason as AcknowledgedBrigadeCoverageDeficitAssessmentReason,
    };
  }

  return {
    assessment: "covered",
    note: assessment.assessmentNote,
    reason:
      assessment.assessmentReason as CoveredBrigadeCoverageDeficitAssessmentReason,
  };
};
