/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Enum specifying reasons for a "covered" brigade coverage deficit assessment.

 */
export type CoveredBrigadeCoverageDeficitAssessmentReason =
  (typeof CoveredBrigadeCoverageDeficitAssessmentReason)[keyof typeof CoveredBrigadeCoverageDeficitAssessmentReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoveredBrigadeCoverageDeficitAssessmentReason = {
  "covered-by-partner-agency": "covered-by-partner-agency",
  "covered-by-aircraft-capability": "covered-by-aircraft-capability",
  "covered-by-neighbouring-resources": "covered-by-neighbouring-resources",
  "covered-by-specialist-resources": "covered-by-specialist-resources",
} as const;
