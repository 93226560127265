import {
  Alert,
  type AlertContent,
  ModalForm,
  onPromise,
  showToast,
} from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  getGetCoverageDistrictsIdBrigadesQueryKey,
  usePutCoverageBrigadesIdAssess,
} from "../../../../.rest-hooks/coverage";
import type {
  AssessBrigadeCoverageDeficitInput,
  BrigadeCoverageSnapshotValid,
} from "../../../../.rest-hooks/types/coverage.yml";
import { getCoverageGapAssessment } from "../../../config/brigadeCoverageAssessment";
import CoverageGapAssessmentForm, {
  type CoverageGapAssessmentFormValues,
  getDefaultCoverageGapAssessmentFormValues,
} from "./CoverageGapAssessmentForm";
import CoverageGapSnapshotHighlight from "./CoverageGapSnapshotHighlight";

const StyledCoverageGapAssessmentModal = styled.div`
  display: grid;
  gap: 16px;
`;

interface CoverageGapAssessmentModalProps {
  districtId: string;
  isOpen: boolean;
  onClose: () => void;
  snapshot: BrigadeCoverageSnapshotValid;
}

const CoverageGapAssessmentModal = ({
  districtId,
  isOpen,
  onClose,
  snapshot,
}: CoverageGapAssessmentModalProps) => {
  const [formError, setFormError] = useState<AlertContent | null>(null);

  const queryClient = useQueryClient();

  const defaultValues = getDefaultCoverageGapAssessmentFormValues(
    snapshot.assessment,
  );

  const form = useForm<CoverageGapAssessmentFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultCoverageGapAssessmentFormValues(snapshot.assessment));
  }, [reset, snapshot]);

  const { mutateAsync: assessCoverageGapMutation } =
    usePutCoverageBrigadesIdAssess({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetCoverageDistrictsIdBrigadesQueryKey(districtId),
          });

          showToast({
            variant: "success",
            title: "Coverage gap assessed",
          });
        },
      },
    });

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<CoverageGapAssessmentFormValues> = async (
    values,
  ) => {
    setFormError(null);

    const assessment = getCoverageGapAssessment(values);

    try {
      await assessCoverageGapMutation({
        id: snapshot.brigadeId,
        data: assessment as AssessBrigadeCoverageDeficitInput,
      });

      onClose();
    } catch (error) {
      setFormError({
        title: "Unable to complete assessment",
        message: "Please try again.",
      });
    }
  };

  return (
    <FormProvider {...form}>
      <ModalForm
        hasHighlight
        id="assessCoverageGaps"
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        error={
          formError && (
            <Alert title={formError.title} variant="error">
              {formError.message}
            </Alert>
          )
        }
        onCancel={onCancel}
        onSubmit={onPromise(handleSubmit(onSubmit))}
        title="Coverage gap assessment"
      >
        <StyledCoverageGapAssessmentModal>
          <CoverageGapSnapshotHighlight
            data-testid="coverage-gap-assessment-form-snapshot"
            snapshot={snapshot}
          />
          <CoverageGapAssessmentForm />
        </StyledCoverageGapAssessmentModal>
      </ModalForm>
    </FormProvider>
  );
};

export default CoverageGapAssessmentModal;
