import {
  FDRCatastrophic,
  FDRExtreme,
  FDRHigh,
  FDRModerate,
  FDRNoRating,
} from "@app/design-system";
import { FireDangerRating } from "../../.rest-hooks/types/common.yml";

const fireDangerRatings = Object.values(FireDangerRating);

export const fireDangerRatingIcons: Record<
  FireDangerRating,
  ReactSVGComponent | null
> = {
  "high-a": FDRHigh,
  "high-b": FDRHigh,
  "no-rating": FDRNoRating,
  "not-available": null,
  catastrophic: FDRCatastrophic,
  extreme: FDRExtreme,
  moderate: FDRModerate,
};

export const fireDangerRatingLabels: Record<FireDangerRating, string> = {
  "not-available": "—",
  "no-rating": "No rating (0-11)",
  "high-a": "High A (24-34)",
  "high-b": "High B (35-50)",
  catastrophic: "Catastrophic (100+)",
  extreme: "Extreme (51-100)",
  moderate: "Moderate (12-23)",
};

export const isFireDangerRating = (
  fireDangerRating: string,
): fireDangerRating is FireDangerRating => {
  return fireDangerRatings.includes(fireDangerRating as FireDangerRating);
};

export const formatFireDangerRating = (fireDangerRating: FireDangerRating) => {
  if (fireDangerRating in fireDangerRatingLabels) {
    return fireDangerRatingLabels[fireDangerRating];
  }

  return fireDangerRating || "—";
};
