import type { BrigadeCoverageSnapshot } from "../../.rest-hooks/types";
import type { BrigadeCoverageSnapshotValid } from "../../.rest-hooks/types/coverage.yml";

const getBrigadeCoverageSnapshotType = (
  snapshot: BrigadeCoverageSnapshot | undefined,
) => {
  if (!snapshot) {
    return "error";
  }
  if ("error" in snapshot && !!snapshot.error) {
    return "error";
  }
  return snapshot as BrigadeCoverageSnapshotValid;
};

export default getBrigadeCoverageSnapshotType;
