import { StatusMessage } from "@app/design-system";
import SeverityIcon from "@app/design-system/src/components/SeverityIcon/SeverityIcon";
import styled, { css } from "styled-components";
import type { BrigadeCoverageSnapshot } from "../../../../.rest-hooks/types/coverage.yml";
import getBrigadeCoverageSnapshotType from "../../../config/getBrigadeCoverageSnapshotType";
import { formatCoverageNet } from "./formatCoverageNet";

interface StyledCoverageNetIndicatorCellProps {
  isIconVisible: boolean;
}

const StyledCoverageNetIndicatorCell = styled.div<StyledCoverageNetIndicatorCellProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  ${(p) =>
    !p.isIconVisible &&
    css`
      padding-left: calc(24px + 12px);
    `}
`;

interface CoverageNetIndicatorCellProps {
  snapshot: BrigadeCoverageSnapshot | undefined;
}

const CoverageNetIndicatorCell = ({
  snapshot,
}: CoverageNetIndicatorCellProps) => {
  const filteredSnapshot = getBrigadeCoverageSnapshotType(snapshot);

  if (
    filteredSnapshot === "error" ||
    filteredSnapshot.coverageImpactSeverity === "unknown"
  ) {
    return (
      <StatusMessage
        tooltip="Coverage gap calculation has failed. Contact ICT or check back later."
        variant="error"
      >
        Failed
      </StatusMessage>
    );
  }

  // If the coverage net is 0, we display "sufficient" instead of a + or -
  // amount. We don't want the  alignment to shift out to align with the other
  // numerical values when we're using a word because it looks off. So this
  // variable is used to conditionally render the SVG when we do need to
  // reserve space for it.
  const hasImpactSeverity =
    filteredSnapshot.coverageImpactSeverity !== "no-impact";

  return (
    <StyledCoverageNetIndicatorCell isIconVisible={hasImpactSeverity}>
      {hasImpactSeverity && (
        <SeverityIcon
          disabled={!!filteredSnapshot.assessment}
          severity={
            filteredSnapshot.coverageImpactSeverity !== "no-impact"
              ? filteredSnapshot.coverageImpactSeverity
              : "moderate"
          }
        />
      )}
      {formatCoverageNet(filteredSnapshot.coverageNet)}
    </StyledCoverageNetIndicatorCell>
  );
};

export default CoverageNetIndicatorCell;
