export const formatCoverageNet = (value: number | undefined) => {
  if (!value) {
    return "—";
  }
  if (value === 0) {
    return "Sufficient";
  }

  if (value > 0) {
    return `+${value}`;
  }

  return `−${Math.abs(value)}`;
};
